<template>
  <div class="container">
    <div class="contentWrap">
      <i class="far fa-smile-wink action-icon-without-pointer" />
      <h1>
        已付款成功！請點擊
        <router-link :to="{ name: 'LandingPage' }">這裡</router-link>
        回首頁！
      </h1>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Stripe Success - Ivy-Way Academy"
    };
  }
};
</script>

<style scoped>
.container {
  display: flex;
  margin: 60px auto;
  font-size: 240px;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 300px);
}

.contentWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  margin-top: 40px;
}
</style>
